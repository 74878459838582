

body {
    background-color: black;
    margin: 0;
    padding: 0;
  }
.hero-content img{
  width: 100%;
  height: 65vh;
}
  

  
  /* Media Query for Smaller Screens */
  @media (max-width: 768px) {
    .hero-content img{
   margin-top: 70px;
      height: 35vh;
    }
  }