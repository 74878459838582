/* navigationbar.css */
.navigation-bar {
  top: 0;
  left: 0;
  height: 60px; /* Use rem or em units for better responsiveness */
  background-color: #F21137;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 100;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #FFFFFE;
  margin-left: 20px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin-right: 50px;
}

.nav-link {
  color: #FFFFFE;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  font-weight: 500;
  font-size: 16px;
}

.menu-icon,
.cross-icon {
  font-size: 24px;
  color: #1c3254;
  cursor: pointer;
  display: none;
}

.search-bar {
  display: none;
  transition: opacity 0.3s, height 0.3s;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.search-bar.open {
  display: block;
  opacity: 1;
  height: auto;
}

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  .logo {
    font-size: 19px;
    font-weight: 900;
    margin-left: 5px;
  }

  .menu-icon, .cross-icon {
    display: block;
  padding-right: 10px !important;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    background-color: #333;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 99;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .nav-links.active {
    display: flex;
  }

  .nav-link {
    font-size: 18px;
  }

  .navigation-bar {
    height: 60px;
    padding: 10px 20px;
    width: 95%;
  }
}
