.container {
    display: flex;
   margin:20px  0;
    align-items: center;
  }
  
  .whatsapp-link {
    display: flex;
 
    font-size: 18px;
    text-decoration: none;
    color: #fff;
    background-color: #25d366;
    padding: 10px 15px;
    border-radius: 50px;
    transition: background-color 0.3s ease;
  }
  
  .whatsapp-link:hover {
    background-color: #128c7e;
  }
  .phone-link{
    display: flex;
    font-size: 18px;
    text-decoration: none;
    color: #fff;
    background-color: black;
    padding: 10px 20px;
    border-radius: 50px;
    margin-left: 5px;
    transition: background-color 0.3s ease;
  }
  
  