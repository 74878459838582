
.employees-section {
    padding: 80px 0;
    text-align: center;
    background: linear-gradient(220deg, #F21137 30%, #333 30%);
    overflow-x: scroll; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent wrapping of items */
  }
  
  .employees-section h2 {
    color: white;
    font-size: 2rem;
  }
  
  .employees-list {
    display: inline-block; /* Display employees in a row */
    margin-top: 20px;
  }
  
  .employee {
    display: inline-block; /* Display employees side by side */
    padding: 20px;
    transition: 0.5s ease;
    margin: 0 10px; 
  }
  
  
  .employee img {
    width: 300px;
    height: 300px;
  border-radius: 40px;
    margin: 0 auto 10px;
       
    }

  

  
  .employee h3 {
    font-size: 1.5rem;
    margin-top: 0;
  }
  
  .employee p {
    color: #666;
    margin-top: 10px;
  }
    /* Media Query for Smaller Screens */
  @media (max-width: 768px) {
    .employees-section h2 {
      font-size: 1.5rem;
    }
    
    .employee {
      padding: 15px;
    }
    
    .employee h3 {
      font-size: 1.2rem;
    }
    
    .employee p {
      font-size: 14px;
    }
  }