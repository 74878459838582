.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #f45f29;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.3s;
  }
  
  .scroll-to-top-button.show {
    display: flex;
  }
  
  .scroll-to-top-button i {
    font-size: 30px;
    margin: auto;
  }
  